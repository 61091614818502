import React, {lazy, Suspense} from 'react';
import Splash from "./src/components/Splash";
const Launcher = lazy(() => import('./src/components/Launcher'));

const App = () => {
    return (
        <Suspense fallback={<Splash/>}>
            <Launcher/>
        </Suspense>
    );
}

export default App;