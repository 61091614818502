import React from "react";
import {ActivityIndicator, Image, StyleSheet, View} from "react-native";
import {globalColors} from "../global/globalStyles";

export default function Splash() {
    return (
        <View style={styles.container}>
            <View style={styles.loading}>
                <>
                    <Image source={require('../../resources/images/logo512.png')} style={{width: 256, height: 256}}/>
                    <ActivityIndicator size="large" color={'#ffffff'}/>
                </>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: globalColors.dark
    },
    loading: {
        height: '100%',
        alignItems: "center",
        justifyContent: "center",
    }
});