import {StyleSheet} from "react-native";

export const remFont = 16;
export const touchableActiveOpacity = 0.7;

export const globalColors = {
    darkOrange: '#e75600',
    lightGrey: '#F6F6F6',
    grey: '#eaeaea',
    brandGreen: '#00865B',
    darkbrandGreen: '#00724c',
    darkGreen: '#74b342',
    green: '#a9c938',
    paleGreen: '#A1AB50',
    blue: '#009a93',
    darkBlue: '#0069b4',
    pink: '#e50050',
    red: '#ea0606',
    orange: '#f39200',
    purple: '#951b81',
    yellow: '#FFCD56',
    taupe: '#70694D',
    darkTaupe: '#5e5841',
    lightBrown: '#cb9b44',
    darkBrown: '#b37c39',
    dark: '#2f4151',
    darker: '#283744',
}

export const primaryColor = globalColors.lightBrown;
export const darkPrimaryColor = globalColors.darkBrown;

export const regularFont = {
    fontFamily: 'Barlow',
    fontWeight: '400' as any,
}

export const semiBoldFont = {
    fontFamily: 'Barlow',
    fontWeight: '500' as any,
}

export const boldFont = {
    fontFamily: 'Barlow',
    fontWeight: '700' as any,
}

export const semiCondensedBoldFont = {
    fontFamily: 'Barlow Semi Condensed',
    fontWeight: '700' as any,
}

export const condensedBoldFont = {
    fontFamily: 'Barlow Condensed',
    fontWeight: '700' as any,
}

export const globalStyles = StyleSheet.create({
    pageContainer: {
        paddingHorizontal: 15,
        paddingVertical: 20,
        flex: 1,
        backgroundColor: '#ffffff',
    },
    textMuted: {
        color: '#6c757d',
        fontSize: 0.95 * remFont,
    },
    sectionTitle: {
        ...condensedBoldFont,
        color: globalColors.dark,
        fontSize: 1.3 * remFont,
        fontWeight: '700' as any,
        marginBottom: 5,
    },
    sectionSubtitle: {
        ...semiCondensedBoldFont,
        color: globalColors.dark,
        fontSize: 1.1 * remFont,
        fontWeight: '700' as any,
        marginBottom: 5,
    },
    link: {
        color: '#be6f00',
    },
    genericButton: {
        alignSelf: 'flex-start',
        backgroundColor: primaryColor,
        paddingVertical: .6 * remFont,
        paddingHorizontal: 1.1 * remFont,
        borderRadius: 5,
    },
    genericButtonLabel: {
        color: '#ffffff',
        ...semiBoldFont,
    },
    smallButton:{
        paddingVertical: .4 * remFont,
        paddingHorizontal: 0.8 * remFont,
    },
    outlineButton: {
        alignSelf: 'flex-start',
        backgroundColor: globalColors.grey,
        paddingVertical: .6 * remFont,
        paddingHorizontal: 1.1 * remFont,
        borderRadius: 5,
    },
    outlineButtonLabel: {
        color: globalColors.dark,
        ...semiBoldFont,
    },
    pageTitle: {
        ...condensedBoldFont,
        fontSize: remFont * 1.4,
    },
    sectionGeneralStyle: {
        paddingVertical: 20,
        paddingHorizontal: 20,
    },
    paragraph: {
        ...regularFont,
        fontSize: remFont,
        marginTop: 0,
        marginBottom: 10
    }
});

export const genericShadow = {
    shadowColor: "#000",
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.35,
    shadowRadius: 3.84,
    elevation: 5,
}