import {AppRegistry, Platform} from 'react-native';
import App from './App';
import './resources/css/fonts.css';

AppRegistry.registerComponent('CbnmcGardenVisitApp', () => App);

/* On déclare ici à React Native Web d'attacher l'application à l'élément #main */
AppRegistry.runApplication('CbnmcGardenVisitApp', {
    rootTag : document.getElementById('main'),
});
